export enum Urls {
  BASE = '/',

  AUTH = 'auth',
  LOGIN = 'login',
  REGISTER = 'register',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  CONFIRM_EMAIL = 'confirm-email',
  CONFIRM_EMAIL_UPDATE = 'confirm-email-update',

  APP = 'app',
  HOMEPAGE = 'home',
  FAQ = 'faqs',
  MARKETPLACE_ACTIVITY = 'marketplace-activity',

  USER_ACCOUNT = 'user-account',

  USER_INFO = 'user-info',
  MY_INFO = 'my-info',
  SETTINGS = 'settings',
  MY_FAVORITES = 'my-favorites',

  CATALOG = 'catalog',
  CLOSE_TO_CLOSING = 'close-to-closing',

  DIRECT_DEALS = 'direct-deals',
  PRIVATE_EQUITY_FUNDS = 'private-equity-funds',
  REAL_ESTATE_FUNDS = 'real-estate-funds',
  MUTUAL_FUNDS_AND_ETFS = 'mutual-funds-and-etfs',

  DETAILS = 'details',

  SUMMARY_TAB = 'summary',
  TEAM_TAB = 'team',
  FINANCIALS_TAB = 'financials',
  INVESTMENT_STRATEGY_TAB = 'investment-strategy',
  DEAL_TAB = 'deal',
  IMPACT_TAB = 'impact',
  CHAMPION_TAB = 'champion',
  PARTNER_TAB = 'partner',
  QUESTIONS_TAB = 'questions',
  UPDATE_TAB = 'updates',
  TERMS_TAB = 'terms',
  FUND_TAB = 'fund',

  PROFILES = 'profiles',
  INVESTORS_AND_CHAMPIONS = 'investors-and-champions',
  PARTNERS = 'partners',
  CHAMPION_PROFILE = 'champion-profile',
  INVESTOR_PROFILE = 'investor-profile',
  PARTNER_PROFILE = 'partner-profile',

  ADMIN = 'admin',
  ADMIN_USER = 'users-permissions',
  SETUP = 'setup',
  FIRMS = 'firms',

  DIRECT_DEAL = 'direct-deal',
  PRIVATE_EQUITY_FUND = 'private-equity-fund',
  REAL_ESTATE_FUND = 'real-estate-fund',
  MUTUAL_FUND_AND_ETF = 'mutual-fund-and-etf',

  NEW_FIRM = 'new-firm',
  NEW_COMPANY = 'new-company',
  EXISTING_FIRM = 'existing-firm',
  EXISTING_COMPANY = 'existing-company',
  UPDATE = 'update',

  COMPANIES = 'companies',
  ARCHIVE = 'archive',

  BANNERS = 'banners',
  WELCOME_BANNERS = 'welcome-banners',
  BANNER_SETUP = 'banner-setup',
  BANNER_UPDATE = 'banner-update',
  SURVEY_SETUP = 'survey-setup',
  SURVEY_UPDATE = 'survey-update',

  USERS_PERMISSIONS = 'users-permissions',
  ORGANIZATIONS = 'organizations',

  REPORTS = 'reports',
  SURVEY_ANSWERS = 'survey-answers',
  WEBSITE_ACTIVITY = 'website-activity',
  QUESTIONS_AND_UPDATES = 'questions-and-updates',
}

export default Urls;
