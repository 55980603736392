import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import Urls from './_core/constants/Urls';


const routes: Routes = [
  {path: '', redirectTo: Urls.APP, pathMatch: 'full'},
  {
    path: Urls.APP,
    loadChildren: () => import('./internal-app/internal-app.module').then(m => m.InternalAppModule)
  },
  {
    path: Urls.AUTH,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '**',
    loadChildren: () => import('./internal-app/internal-app.module').then(m => m.InternalAppModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
