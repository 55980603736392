// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require('../../package.json').version,
  production: false,
  server: 'https://dev.marketplace.faithdriveninvestor.org/api',
  authServer: 'https://dev.groups.faithdrivenentrepreneur.org/api-auth',
  secure: false,
  changeOrigin: true,
  placesApiKey: 'AIzaSyAP_QSIbrgr7186CebjAOsFmgr9mQ45o7o',
  recaptchaApiKey: '6LcmW9oaAAAAAH7CduvrhqLxMmtGeRC7JHR9TQLr',
  firebaseConfig: {
    apiKey: 'AIzaSyBTq-6XAYRvyFz554F9blkWoGZy0KCf-is',
    authDomain: 'faith-driven.firebaseapp.com',
    projectId: 'faith-driven',
    storageBucket: 'faith-driven.appspot.com',
    messagingSenderId: '341019504498',
    appId: '1:341019504498:web:67a62566c97dee596937eb',
    measurementId: 'G-7W6D22WS28',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
