import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  // prettier-ignore
  constructor(
    private analytics: AngularFireAnalytics,
  ) { }

  logScreenView(screen: string): void {
    this.analytics.logEvent('screen_view', { firebase_screen: screen });
  }

  logClickEvent(clickEvent: string): void {
    this.analytics.logEvent('click', { clickEvent: clickEvent });
  }
}
