import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Configuration {
  server = environment.server;
  authServer = environment.authServer;
  environment = environment.production;

  constructor() {}
}
