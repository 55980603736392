import { Injectable } from '@angular/core';
import StorageHelper from '../helpers/Storage.helper';
import { UserDetails } from '../models/User';
import { BehaviorSubject, Observable, of } from 'rxjs';
import Urls from '../constants/Urls';
import { Router } from '@angular/router';
import { AccountService } from '../api/account.service';
import { catchError, tap } from 'rxjs/operators';
import { ResponseObject } from '../models/GenericObject';
import { ToastrService } from 'ngx-toastr';
import { ToastrMessages } from '../constants/ToastrMessages';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _currentUser: UserDetails = null;
  private currentUserSource = new BehaviorSubject<UserDetails>(null);
  promise: Promise<any>;
  currentUser$ = this.currentUserSource.asObservable();

  // prettier-ignore
  constructor(
    private router: Router,
    private accountService: AccountService,
    private toastrService: ToastrService
  ) {}

  getUserInfo(): Observable<any> {
    return this.accountService.getUserInfo().pipe(
      tap((response: ResponseObject<UserDetails>) => {
        this.setCurrentUser(response.response);
      }),
      catchError(() => {
        this.toastrService.error(...ToastrMessages.USER_PROFILE_ERROR);
        StorageHelper.killSession();
        return of(null);
      })
    );
  }

  get currentUser(): UserDetails {
    return this._currentUser;
  }

  setCurrentUser(currentUser: UserDetails) {
    this.currentUserSource.next(currentUser);
    this._currentUser = currentUser;
    this.promise = this.currentUserSource.toPromise();
  }

  isLoggedIn() {
    return this._currentUser != null;
  }

  logout(): void {
    this.accountService.logout().subscribe(() => {
      this.killSession();
    });
  }

  killSession(): void {
    StorageHelper.killSession();
    this.setCurrentUser(null);
    this.router.navigate([Urls.BASE]);
  }
}
