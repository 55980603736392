import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from './_core/services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // prettier-ignore
  constructor(
    private router: Router,
    private analyticsService: AnalyticsService,
  ) {
    this.router.events.subscribe((val: NavigationEnd) => {
      if(val?.url && val instanceof NavigationEnd) {
        this.analyticsService.logScreenView(val?.url);
      }
      
    })
  }
}
