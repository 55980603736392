export const ToastrMessages = {
  BASIC_ERROR: ['There was a problem with the server, please try again later'],
  USER_PROFILE_ERROR: ['There is a problem with your account. Please contact the Faith Driven Investor team for assistance'],
  EXPIRED_SESSION: ['Please sign in again', 'Expired session'],
  NOT_FOUND: ['Resource not found'],

  ACCOUNT_CREATED: ['Your account has been successfully created'],
  FORGOT_PASSWORD: ['An email has been sent with instructions for you to reset your password'],
  FORGOT_PASSWORD_ADMIN: ['An email has been sent with instructions for the user to reset his password'],
  RESET_PASSWORD: ['Password was successfully reset'],
  CAPTCHA_INVALID: ['Captcha Invalid'],
  EMAIL_USED: ['There is another account with this email'],
  EMAIL_RESENT: ['A confirmation email was successfully sent'],
  USER_NOT_FOUND: ['The email address you entered doesn\'t match any account. Please click "Register" to sign up for an account.'],
  LINK_EXPIRED: ['The link you clicked is no longer valid. Check for a newer email with the newer link.'],
  USER_UNVERIFIED: ['You must first verify your account before signing in'],
  BAD_CREDENTIALS: ['Invalid email address or password'],
  NEW_PASSWORD_SAME_AS_OLD: [
    "Please choose a password that you haven't used before. For your account protection, " +
      'you need to choose a new password every time you reset it.',
  ],
  INVALID_OLD_PASSWORD: ['Password change failed, please check your password and try again'],
  INVALID_PASSWORD_FORMAT: ['Password must include at least one lowercase, one uppercase, one symbol, one digit and have a minimum of 8 characters'],
  FIRST_STEP_NEEDED: ['You must complete the first step in order to be able to save.'],
  FIRST_STEP_ASSET_NEEDED: ['You must complete the deal information in order to be able to save.'],
  DEAL_NOT_STARTED: ['You must save deal information in order to see a preview.'],
  INCOMPLETE_FORM: ['You must complete all the fields before submitting'],
  INCORRECT_VALUES: ['The form contains incorrect values'],
  INCOMPLETE_FORM_TERMS_NOT_SELECTED: [
    'You must complete all the fields and agree to Code of Conduct, Terms of Service and Privacy Policy before submitting',
  ],
  INCOMPLETE_FORM_ALL_TERMS_NOT_SELECTED: [
    'You must complete all the fields and agree to Code of Conduct, Terms of Service and Privacy Policy, and Accredited Investors Disclosure Requirements before submitting',
  ],
  TERMS_NOT_SELECTED: ['You must agree to Code of Conduct, Terms of Service and Privacy Policy before submitting'],
  ALL_TERMS_NOT_SELECTED: [
    'You must agree to Code of Conduct, Terms of Service and Privacy Policy, and Accredited Investors Disclosure Requirements before submitting',
  ],
  NO_INVESTOR_OPTION_SELECTED: ['You must select an option for  Accredited Investors Disclosure before submitting'],
  INVALID_FORM: ['There are some invalid fields'],
  ADDRESS_INVALID_FORM: ['There are some invalid fields in the address'],
  UPLOAD_VIDEO_DENIEND: ['In order to upload the champion files, you must complete the Deal Information on step 5'],

  ASSET_NOT_VISIBLE: ['You do not have enough rights to view this resource'],
  ASSET_NOT_PUBLISHED: ['Deal is not published therefore you cannot view or add updates'],

  INVALID_FORMAT: ['File has an invalid format'],
  FILE_SIZE: ['File exceeds the limit of 2GB'],
  NO_PREVIEW_FORMAT: ['File has a format that you cannot preview'],
  ERROR_MAX_FILE_SIZE: ['File exceeds the maximum file size permitted'],
  DOCUMENT_DOWNLOAD: ['File was successfully downloaded'],
  ERROR_DOCUMENT_DOWNLOAD: ['File could not be downloaded'],
  INFO_NO_DOCUMENT: ['This document was not uploaded by the company'],
  REMOVE_FILE: ['File was successfully deleted'],
  ERROR_REMOVE_FILE: ['File could not be deleted'],
  COVER_IMAGE: ['You cannot delete the cover image'],
  FILE_UPLOADED: ['File has been successfully uploaded'],
  VIDEO_UPLOAD_ERROR_ASSIST: [
    'There was an error uploading the file. Please contact team@faithdriveninvestor.org with the file attached. Our team will assist with the file upload.',
  ],
  ERROR_ADD_LOCAL_PARTNER: ['The local partner you added could not be saved'],

  NO_CHANGES: ['There are no changes to save'],
  SUCCESS_CHANGE: ['Your changes have been saved'],
  DEAL_ADDED: ['Deal was successfully added'],
  DEAL_UPDATED: ['Deal was successfully updated'],
  DEAL_OR_FUND_FDI_SCORE: ['FDI score has been successfully updated'],
  COMPANY_ADDED: ['Company was successfully added'],
  COMPANY_UPDATED: ['Company was successfully updated'],
  COMPANY_RESTORED: ['Company was succesfully restored'],
  DELETE_COMPANY: ['Company was successfully deleted'],
  COMPANY_NAME_INVALID: ['The Company Name should be filled in before saving.'],
  DELETE_DEAL: ['Deal was successfully deleted'],
  ERROR_DELETE_DEAL: ['Deal could not be deleted'],
  ERROR_DELETE_COMPANY: ['Company could not be deleted'],
  ERROR_DELETE_COMPANY_ADMIN_ASSIGNED: ['Company could not be deleted because it has a company admin assigned'],
  ERROR_DELETE_COMPANY_HAS_PUBLISHED_DEALS: ['Company could not be deleted because it has published deals'],
  ERROR_RESTORE_COMPANY: ['Company could not be restored'],
  INVESTMENT_TYPE_NOT_SELECTED: ['An Investment Type must be selected'],

  FIRM_RESTORED: ['Firm was succesfully restored'],
  ERROR_RESTORE_FIRM: ['Firm could not be restored'],
  DELETE_FIRM: ['Firm was successfully deleted'],
  FIRM_NAME_INVALID: ['The Firm Name should be filled in before saving'],
  ERROR_DELETE_FIRM_HAS_PUBLISHED_FUNS: ['Firm could not be deleted because it has published funds'],
  ERROR_DELETE_FIRM_ADMIN_ASSIGNED: ['Firm could not be deleted because it has a firm admin assigned'],
  ERROR_DELETE_FIRM: ['Firm could not be deleted'],
  DELETE_FUND: ['Fund was successfully deleted'],
  ERROR_DELETE_FUND: ['Fund could not be deleted'],
  ERROR_UPLOAD_FUND_NOT_CREATED: ['You must first create a fund before uploading a file'],
  ERROR_FUND_NAME_MISSING: ['The fund cannot be saved without a name'],
  ERROR_FUND_NAME_MISSING_FOR_UPLOAD: ['Cannot upload since fund name is missing'],

  TOGGLE_VISIBILITY: ['Deal was successfully updated'],
  FUND_TOGGLE_VISIBILITY: ['Fund was successfully updated'],
  ERROR_TOGGLE_FUND_VISIBILITY: ['Fund could not be updated since it is not complete'],
  ERROR_TOGGLE_VISIBILITY_CONFLICT: ['Deal could not be updated since it is not complete'],
  ERROR_TOGGLE_VISIBILITY_TRUE: ['Deal could not be published'],
  ERROR_TOGGLE_VISIBILITY_FALSE: ['Deal could not be withdrawn'],
  ERROR_TOGGLE_FUND_VISIBILITY_FALSE: ['Fund could not be withdrawn'],
  ERROR_TOGGLE_FUND_VISIBILITY_TRUE: ['Fund could not be published'],

  CHANGE_PERMISSION: ['Changes have been successfully updated'],
  CHANGE_PERMISSION_ERROR: ['There was a problem changing the role for this user'],
  CHANGE_VETTED_ERROR: ['There was a problem changing the vetted permision for this user'],
  DELETE_USER: ['User was successfully deleted'],
  DELETE_USER_ERROR: ['User could not be deleted'],
  DELETE_USER_CONFLICT: ['You cannot delete your own user'],
  DELETE_USER_SPONSOR_ACTIVE: ["This user is currently a sponsor on a deal. You can't remove this for the moment."],
  DELETE_USER_LINKED: ['This user can not be edited or deleted due to being linked to a compay/firm.'],

  IMAGE_FORMAT_ERROR: ['Image format not recognized. Allowed formats are .JPEG .JPG and .PNG'],
  LOAD_IMG_IN_CROPPER_ERROR: ['There was an error loading the uploaded image in the crop tool'],
  UPDATE_PROFILE_IMG: ['Your profile image was successfully updated'],
  UPDATE_PROFILE_IMG_ERROR: ['Your profile image could not be updated'],
  ROLE_CHANGE_INVALID: ['You cannot make this change'],
  PARTNER_ALREADY_ASSIGNED: ['This organization name is already assigned to another Partner Admin'],
  TOGGLE_WATCHLIST_ADD: ['Project was added to your watchlist'],
  TOGGLE_WATCHLIST_REMOVE: ['Project was removed from your watchlist'],
  CONTACT_COMPANY: ['The company was sent an email'],
  SURVEY: ['SurveyAdmin response was sent'],

  ACCREDITED_INVESTOR_OPTIONS: ['You must select at least one option'],
  BANNER_ADDED: ['The banner was successfully added'],
  BANNER_VIDEO_ADDED: ['The video was successfully added'],
  BANNER_IMAGE_ADDED: ['The image was successfully added'],
  BANNER_EDITED: ['The banner was successfully edited'],
  BANNER_REMOVED: ['The banner was successfully deleted'],
  NO_BANNER_IMAGE: ['Please upload an image for your banner'],
  NO_BANNER_SECTION: ['Please choose an item from the dropdown'],
  LINK_AND_VIDEO_CANNOT_COEXIST: ['Please choose either a link or a video file for your image banner'],
  NUMBER_OF_BANNERS_EXCEEDED: ['No more than 12 banner-list can be uploaded'],
  MINIMUM_NUMBER_OF_BANNERS_REACHED: ['At least 3 banner-list are required'],

  WELCOME_POPUP_IMAGE_UPLOADED: ['The welcome popup image was successfully uploaded'],
  WELCOME_POPUP_DELETE: ['The welcome popup was successfully removed'],
  WELCOME_POPUP_SAVE_CHANGES: ['The changes on welcome popup were saved'],
  WELCOME_POPUP_ADDED_POPUP: ['The welcome image popup was successfully added'],
  WELCOME_POPUP_INCOMPLETE_FIELDS: ['Image and link are required'],

  SURVEY_ADDED: ['The survey was succesfully added.'],
  SURVEY_REMOVED: ['This survey was succesfully removed.'],
  SURVEY_EDITED: ['The survey was successfully edited'],

  FIRM_ADDED: ['Firm was successfully added'],
  FIRM_UPDATED: ['Firm was successfully updated'],
  FUND_ADDED: ['Fund was successfully added'],
  FUND_UPDATED: ['Fund was successfully updated'],

  QUESTION_POSTED: ['Question was successfully posted'],
  QUESTION_EDITED: ['Question was successfully edited'],
  ANSWER_POSTED: ['Answer was successfully posted'],
  ANSWER_EDITED: ['Answer was successfully edited'],

  UPDATE_PUBLISHED: ['Update was successfully published'],
  UPDATE_SAVED: ['Update was successfully saved'],
  UPDATE_DELETE_SUCCESS: ['Update was successfully deleted'],
  UPDATE_MISSING_REQUIRED_FIELDS: ['Your update has invalid fields'],
  UPDATE_NO_COMPANY_ADMIN: ["Update can not be published because your company doesn't have a company admin"],

  INVITATION_RESEND_SUCCESS: ['Invitation was resent'],
  INVITATION_DELETED_SUCCESS: ['Invitation was deleted'],
  INVITE_USERS_ALREADY_INVITED: ['Some of the requested invitations were not sent'],
  INVITATION_DELETED_SUCCES: ['The invitation was succesfully deleted'],
  INVITATION_DELETE_ERROR: ['The invitation could not be deleted'],

  UPLOAD_IN_PROGRESS: ['Please wait until the file uploading finishes'],
  SURVEY_ANSWERED: ['The survey was answered'],
  SURVEY_SKIPPED: ['The survey was skipped'],
  SURVEY_INVALID: ['The survey is invalid, please select an answer.'],

  TOKEN_NOT_FOUND: [
    'This link is no longer available, please fill in your email address so that a new email to reset your password can be sent to you.',
  ],
  COPIED_TO_CLIPBOARD: ['The link has been copied in the clipboard'],
  SYNCHRONIZE_HUBSPOT: ['Hubspot was successfully synchronized'],
  HUBSPOT_ERROR: ['There was a problem with the hubspot synchronization'],
};
