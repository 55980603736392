import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import StorageHelper from '../helpers/Storage.helper';
import { TokenData } from '../models/Auth';
import { Configuration } from '../services/app.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // prettier-ignore
  constructor(
    private config: Configuration,
    private http: HttpClient
  ) {
  }

  login(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const body = new HttpParams()
      .set('username', email.trim())
      .set('password', encodeURIComponent(password.trim()))
      .set('grant_type', 'password')
      .set('scope', 'message:read')
      .set('platform', 'Marketplace');

    return this.http.post(`${this.config.authServer}/oauth/token`, body.toString(), { headers });
  }

  refreshToken() {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const body = new HttpParams()
      .set('username', StorageHelper.getUsername())
      .set('refresh_token', StorageHelper.getRefreshToken())
      .set('grant_type', 'refresh_token')
      .set('scope', 'message:read')
      .set('platform', 'Marketplace');

    return this.http
      .post(`${this.config.authServer}/oauth/token`, body.toString(), { headers })
      .pipe(
        tap((response: TokenData) => StorageHelper.saveTokensAndUsername(response.access_token, response.refresh_token, StorageHelper.getUsername()))
      );
  }

  forgotPassword(email) {
    return this.http.post(`${this.config.authServer}/forgot-password?email=${email.trim()}&platform=MARKETPLACE`, null);
  }

  resetPassword(token, newPassword) {
    return this.http.post(`${this.config.authServer}/reset-password`, { token, newPassword });
  }

  changePassword(body) {
    return this.http.post(`${this.config.authServer}/change-password`, body);
  }
}
