import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import StorageHelper from '../helpers/Storage.helper';
import { LoaderService } from '../services/loader.service';
import { catchError, concatMap, exhaustMap, first, tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrMessages } from '../constants/ToastrMessages';
import { AuthService } from '../api/auth.service';
import { TokenData } from '../models/Auth';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: Subject<any> = new Subject<any>();

  // prettier-ignore
  constructor(
    private loaderService: LoaderService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private injector: Injector
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    this.loaderService.show();
    request = this.setHeaders(request);
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.loaderService.hide();
        }
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          this.loaderService.hide();
          if (error.status === 400 && error?.error?.error_description?.indexOf('Invalid refresh token') > -1) {
            this.injector.get(UserService).killSession();
            this.isRefreshing = false;
            location.reload();
            this.toastrService.error(...ToastrMessages.EXPIRED_SESSION);
            return of(error);
          } else if (error.status === 401) {
            return this.handle401Error(request, next);
          } else {
            const err = error.error.message || error.error.error;
            return throwError(err);
          }
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshing) {
      StorageHelper.clearToken();
      this.isRefreshing = true;
      return this.authService.refreshToken().pipe(
        exhaustMap((tokenData: TokenData) => {
          StorageHelper.saveToken(tokenData.access_token);
          this.refreshTokenSubject.next(tokenData.access_token);
          this.isRefreshing = false;
          return next.handle(this.setHeaders(request));
        }),
        catchError((error: any) => {
          this.injector.get(UserService).killSession();
          location.reload();
          this.toastrService.error(...ToastrMessages.EXPIRED_SESSION);
          return of(error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        first(),
        concatMap(() => next.handle(this.setHeaders(request)))
      );
    }
  }

  private setHeaders(request: HttpRequest<any>) {
    let headers = request.headers.set('Access-Control-Allow-Origin', '*').set('Cache-Control', 'no-cache').set('Pragma', 'no-cache');
    const token = StorageHelper.getToken();

    headers = token
      ? headers.set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`)
      : headers.set('Authorization', 'Basic bWFya2V0cGxhY2U6c2VjcmV0');

    return request.clone({ headers });
  }
}
